import { ButtonLinkPrimary } from '/components/buildingBlocks/Button'
import { HeadingPrimaryXl } from '/components/buildingBlocks/Heading'
import { TextPrimaryMd } from '/components/buildingBlocks/Text'
import { useRelativeScroll } from '/machinery/useRelativeScroll'
import { animated } from 'react-spring'
import { routeMap } from '/routeMap'

import styles from './Introduction.css'

export function Introduction({ layoutClassName }) {
  const { ref: scrollRef, value } = useRelativeScroll()

  return (
    <div ref={scrollRef} className={cx(styles.component, layoutClassName)} id="introduction">
      <div className={styles.container}>
        <Visual springValue={value} layoutClassName={styles.backgroundLayout} />

        <div className={styles.inner}>
          <HeadingPrimaryXl layoutClassName={styles.headingLayout} heading='A perfect **match**' h={2} />
          <TextPrimaryMd layoutClassName={styles.textLayout}>
            The Aston Martin F1® Team is always seeking technical and corporate professionals who not only possess top-notch knowledge but also the right mindset,
            genuine team players who enjoy new challenges, innovating and creative solutions.
            This is exactly the DNA upon which Circle8 is based:
            we embrace the idea that a seamless flow of talent fosters mutual growth for both
            individuals and organizations. We call it 'Total Talent Flow'.
          </TextPrimaryMd>
          <ButtonLinkPrimary href={routeMap.pressRelease()}>
            Read the press release
          </ButtonLinkPrimary>
        </div>
      </div>
    </div>
  )
}

function Visual({ springValue, layoutClassName }) {
  return (
    <span role='presentation' className={cx(styles.componentVisual, layoutClassName)}>
      <svg viewBox="0 0 1102 1355" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 804.091C0 1107.86 247.184 1355 551 1355h44.668v-342.39H551c-114.957 0-208.554-93.581-208.554-208.519 0-114.939 93.597-208.521 208.554-208.521 114.957 0 208.554 93.582 208.554 208.521v44.66H1102v-44.66c0-147.384-58.97-280.745-153.615-379.715C1043.03 325.406 1102 192.044 1102 44.661V0H759.554v44.66c0 114.939-93.597 208.521-208.554 208.521-303.816 0-551 247.143-551 550.91Z" fill="url(#a)" fillOpacity=".49" />
        <path d="m947.662 423.685-.661.691.661.691C1042.14 523.863 1101 656.981 1101 804.091v43.66H760.554v-43.66C760.554 688.6 666.51 594.57 551 594.57S341.446 688.6 341.446 804.091c0 115.491 94.044 209.519 209.554 209.519h43.668V1354H551C247.736 1354 1 1107.3 1 804.091c0-303.214 246.736-549.91 550-549.91 115.51 0 209.554-94.029 209.554-209.52V1H1101v43.66c0 147.11-58.86 280.229-153.338 379.025Z" stroke="url(#b)" strokeWidth="2" />
        <defs>
          <linearGradient id="a" x1="202.929" y1="1242.08" x2="971.915" y2="80.836" gradientUnits="userSpaceOnUse">
            <stop stopColor="#318E82" />
            <animated.stop offset={springValue} stopColor="#0E2822" stopOpacity="0" />
          </linearGradient>
          <linearGradient id="b" x1="450" y1="1032" x2="551" y2="1323.5" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAFF17" stopOpacity="0" />
            <animated.stop offset={springValue} stopColor="#FAFF17" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}
